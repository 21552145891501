var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":_vm.image ? '60vw' : '600px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","rounded":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('v-icon',[_vm._v("mdi-cloud-upload-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-alert',{attrs:{"type":"info","colored-border":"","border":"left","color":"primary"}},[_vm._v(" Accepted image format are "),_c('b',[_vm._v("*.png")]),_vm._v(" "),_c('b',[_vm._v("*.jpg")]),_vm._v(" "),_c('b',[_vm._v("*.jpeg")])]),_c('v-file-input',{attrs:{"truncate-length":"15","accept":"image/png,image/jpeg,image/jpg","label":("Select " + _vm.label + " file")},on:{"change":_vm.upload_image},model:{value:(_vm.selected_file),callback:function ($$v) {_vm.selected_file=$$v},expression:"selected_file"}}),(_vm.image)?_c('v-alert',{attrs:{"type":"info","colored-border":"","border":"left","color":"primary"}},[_vm._v(" Use handles to adjust cropping. You can use scroll to zoom in/out. Handles provides exact aspect ratio required for application. ")]):_vm._e(),(_vm.image)?_c('cropper',{ref:"cropper",attrs:{"src":_vm.image,"stencil-props":{
          aspectRatio: _vm.maxWidth / _vm.maxHeight,
        },"canvas":{
          maxHeight: _vm.maxHeight,
          maxWidth: _vm.maxWidth,
        },"size-restrictions-algorithm":_vm.percentsRestriction,"image-restriction":"none"}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save_result}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"25","indeterminate":"","color":"primary"}}):_c('span',[_vm._v("Save result")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }