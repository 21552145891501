<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :max-width="image ? '60vw' : '600px'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" rounded v-bind="attrs" v-on="on">
          <span class="mr-2">
            {{ label }}
          </span>
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-4">
        <v-alert type="info" colored-border border="left" color="primary">
          Accepted image format are <b>*.png</b> <b>*.jpg</b> <b>*.jpeg</b>
        </v-alert>
        <v-file-input
          v-model="selected_file"
          truncate-length="15"
          accept="image/png,image/jpeg,image/jpg"
          :label="`Select ${label} file`"
          @change="upload_image"
        ></v-file-input>

        <v-alert
          type="info"
          v-if="image"
          colored-border
          border="left"
          color="primary"
        >
          Use handles to adjust cropping. You can use scroll to zoom in/out.
          Handles provides exact aspect ratio required for application.
        </v-alert>

        <cropper
          v-if="image"
          ref="cropper"
          :src="image"
          :stencil-props="{
            aspectRatio: maxWidth / maxHeight,
          }"
          :canvas="{
            maxHeight: maxHeight,
            maxWidth: maxWidth,
          }"
          :size-restrictions-algorithm="percentsRestriction"
          image-restriction="none"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save_result"
            ><v-progress-circular
              size="25"
              indeterminate
              color="primary"
              v-if="loading"
            /><span v-else>Save result</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { upload_string } from "@/utils/storage";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
  },
  props: {
    toMimeType: { type: String, default: "image/png" },
    label: { type: String, required: true },
    minWidth: { type: Number, required: true },
    maxWidth: { type: Number, required: true },
    minHeight: { type: Number, required: true },
    maxHeight: { type: Number, required: true },
    fileDestination: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
      image: null,
      selected_file: null,
      loading: false,
    };
  },
  methods: {
    save_result() {
      this.loading = true;
      const { canvas } = this.$refs.cropper.getResult();

      canvas.toBlob(
        (blob) => {
          upload_string(blob, this.fileDestination).then((url) => {
            this.$emit("update-url", url);
            this.loading = false;
            this.dialog = false;
            this.image = null;
            this.selected_file = null;
          });
        },
        this.toMimeType,
        0.9
      );
    },
    percentsRestriction({
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      imageSize,
    }) {
      return {
        maxWidth: (imageSize.width * (maxWidth || 100)) / 100,
        maxHeight: (imageSize.height * (maxHeight || 100)) / 100,
        minWidth: (imageSize.width * (minWidth || 0)) / 100,
        minHeight: (imageSize.height * (minHeight || 0)) / 100,
      };
    },
    upload_image(image) {
      if (image) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(image);
      }
    },
  },
};
</script>
