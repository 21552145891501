<template>
  <div>
    <v-form
      class="brand-form mb-8"
      :class="{ 'pt-4': !is_editing }"
      v-model="is_form_valid"
    >
      <div class="bordered pa-8 mb-4">
        <p class="text-h4 font-weight-bold text-center">
          {{ is_editing ? `Edit ${brand.name}` : "Create New Brand" }}
        </p>
        <v-text-field
          v-model="brand.name"
          autocomplete="new-password"
          label="Name"
          class="required"
          required
        />
        <v-text-field
          v-model="brand.contact"
          label="Contact Email For Brand Owner"
          :rules="[field_req]"
          class="required"
          required
        />
        <v-select
          v-model="brand.brand_type"
          :items="BRAND_TYPES"
          class="required"
          :disabled="is_editing"
          label="Brand Type"
          :rules="[field_req]"
          required
        ></v-select>
        <v-text-field
          v-model="brand.url"
          autocomplete="new-password"
          label="Brand Domain URL"
          :rules="[
            field_req,
            (v) =>
              (v && v[v.length - 1] === '/') ||
              'URL must ends with trailing slash (/)',
          ]"
          class="required"
          placeholder="Must start with 'https:// and end with a trailing '/'"
          hint="eg. https://order.wearelocals.co.uk/"
          required
        />
        <template v-if="brand.brand_type === 'single_shop'">
          <v-alert type="warning" colored-border border="left"
            >Single Brand requires to be attached to an existing shop. If shop
            does not exist
            <router-link :to="{ name: 'admin-register' }"
              >create it</router-link
            >
            first.</v-alert
          >
          <v-autocomplete
            v-model="brand.shop_id"
            :loading="loading_shops"
            :items="shops"
            item-text="id"
            item-value="id"
            :search-input.sync="search_shop"
            :rules="[field_req]"
            cache-items
            required
            :value="brand.shop_id"
            class="required"
            label="Brand Shop"
          ></v-autocomplete>
        </template>
        <template
          v-else-if="['retail', 'hospitality'].includes(brand.brand_type)"
        >
          <v-textarea
            v-model="brand.header"
            autocomplete="new-password"
            label="Brand Home Page Header"
            :rules="[field_req]"
            class="required"
            counter="60"
            :placeholder="brand_header_placeholder"
            required
          />
          <v-textarea
            v-model="brand.subheader"
            autocomplete="new-password"
            label="Brand Home Page Subheader"
            :rules="[field_req]"
            class="required"
            counter="160"
            :placeholder="brand_subheader_placeholder"
            required
          />
        </template>
        <div class="d-flex flex-column flex-md-row">
          <p class="mr-4 light-grey-text">
            Theme Color <span class="red--text">*</span>
          </p>
          <v-color-picker
            v-model="brand.theme_color"
            :rules="[field_req]"
            class="ma-2"
            mode="hexa"
            hide-mode-switch
          ></v-color-picker>
        </div>
      </div>
      <div class="bordered pa-8 mb-4">
        <p class="text-h4 font-weight-bold text-center">Shop Zone</p>
        <v-alert type="info" colored-border border="left">
          This is where you can choose specific zones for your shops, which will
          be filterable in your marketplace. <br />
          You can choose from labels like area, street, town village, location,
          district and zone.
        </v-alert>
        <v-select
          v-model="brand.zone_label"
          class="text-capitalize"
          label="Zone label"
          :items="brand_zone_labels"
        >
          <template v-slot:item="{ item }">
            <span class="text-capitalize">{{ item }}</span>
          </template>
        </v-select>
        <div class="pa-4 my-4 bordered">
          <p class="font-weight-bold text-capitalize">
            Example {{ brand.zone_label }} Filter preview
          </p>
          <v-alert type="info" colored-border border="left">
            This is an example preview of the Shop Zone filter to show you what
            it will look like for a user to select shops in the specific zones
            you create.
          </v-alert>
          <v-select
            v-model="brand_zone_preview"
            style="max-width: 350px"
            class="mb-8 mt-2"
            hide-details
            :placeholder="`Select ${brand.zone_label === 'area' ? 'an' : 'a'} ${
              brand.zone_label
            }`"
            prepend-icon="mdi-map-marker-radius"
            multiple
            :items="brand.zones"
            item-text="name"
            clearable
          >
            <template #selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span v-if="brand_zone_preview.length === 1">{{
                  item.name
                }}</span>
                <span v-else>{{ brand_zone_preview.length }} selected</span>
              </v-chip>
            </template>
          </v-select>
        </div>
      </div>

      <div class="bordered pa-8 mb-4" :class="{ disabled: !brand_id }">
        <p class="text-h4 font-weight-bold text-center">
          Search Engine Optimization
        </p>
        <v-alert type="warning" color="primary" colored-border border="left"
          >This section is to manage what will be displayed on the search engine
          result page for your home page. The big headline written in blue above
          the webpage link is called the meta title. The text underneath the
          headline below the link is called the meta description</v-alert
        >
        <v-text-field
          v-model="brand.html_title"
          autocomplete="new-password"
          label="Meta Title"
          :rules="[field_req]"
          class="required"
          counter="40"
          placeholder="Title of a website which will appear eg. in Google search results"
          hint="Title of a website which will appear eg. in Google search results. Should be something short that will not exceed 60 characters once dynamically added to rest of page title like. eg '| Checkout'"
          required
        />
        <v-textarea
          v-model="brand.html_description"
          autocomplete="new-password"
          label="Meta Description"
          :rules="[field_req]"
          class="required mb-4"
          counter="160"
          placeholder="Description of a website which will appear eg. in Google search results"
          hint="Description of a website which will appear eg. in Google search results. Should be something short that will not exceed 160 characters"
          required
        />
        <div class="google-preview bordered pa-8">
          <p class="font-weight-bold">Example Google Search Result</p>
          <p class="results-returned">
            About 151,000,000 results (0.46 seconds)
          </p>

          <ul>
            <li>
              <h2 class="result-link">
                <a>{{ brand.html_title || "Please input brand title" }}</a>
              </h2>
              <p class="green-link">
                {{ brand.url || "Please input brand url" }}
              </p>
              <span class="down-arrow"></span>
              <p>
                {{ brand.html_description || "Please input brand description" }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="bordered pa-8 mb-4" :class="{ disabled: !brand_id }">
        <p class="text-h4 font-weight-bold text-center">Images & Logos</p>
        <div
          class="img-preview-box align-center mb-4"
          v-if="brand.brand_type !== 'single_shop'"
        >
          <ImagesCropper
            label="Upload Home page background image"
            class="justify-self-start pl-10"
            to-mime-type="image/jpeg"
            :max-width="1920"
            :min-width="1920"
            :min-height="1080"
            :max-height="1080"
            @update-url="(url) => $set(brand, 'image_url', url)"
            :file-destination="`brands/${brand_id}`"
          />
          <div
            v-if="brand.image_url"
            class="image-preview --home-img"
            :style="`background-image: url('${brand.image_url}');`"
          ></div>
        </div>
        <div class="img-preview-box align-center mb-4">
          <ImagesCropper
            label="Upload Brand Logo"
            class="justify-self-start pl-10"
            :max-width="200"
            :min-width="200"
            :min-height="56"
            :max-height="56"
            @update-url="(url) => $set(brand, 'logo', url)"
            :file-destination="`brands/${brand_id}`"
          />
          <div
            v-if="brand.logo"
            class="image-preview"
            :style="`background-image: url('${
              brand.logo
            }'), url(${require('@/assets/images/square-bg.jpg')})`"
          ></div>
        </div>
        <div class="img-preview-box align-center mb-4">
          <ImagesCropper
            label="Upload Brand Logo White"
            class="justify-self-start pl-10"
            :max-width="200"
            :min-width="200"
            :min-height="56"
            :max-height="56"
            @update-url="(url) => $set(brand, 'logo_white', url)"
            :file-destination="`brands/${brand_id}`"
          />
          <div
            v-if="brand.logo_white"
            class="image-preview"
            :style="`background-image: url('${
              brand.logo_white
            }'), url(${require('@/assets/images/square-bg.jpg')})`"
          ></div>
        </div>
        <div class="img-preview-box align-center">
          <ImagesCropper
            label="Upload Brand Favicon"
            class="justify-self-start pl-10"
            :max-width="50"
            :min-width="50"
            :min-height="50"
            :max-height="50"
            @update-url="(url) => $set(brand, 'favicon_url', url)"
            :file-destination="`brands/${brand_id}`"
          />
          <div
            v-if="brand.favicon_url"
            class="image-preview --favicon"
            :style="`background-image: url('${
              brand.favicon_url
            }'), url(${require('@/assets/images/square-bg.jpg')})`"
          ></div>
        </div>
      </div>
      <div class="bordered pa-8 mb-4" :class="{ disabled: !brand_id }">
        <p class="text-h4 font-weight-bold text-center">
          Social Media & Other Info (Optional)
        </p>
        <v-text-field
          v-model="brand.sign_up_url"
          autocomplete="new-password"
          label="Brand Support Group URL"
          prepend-icon="mdi-book-information-variant"
          hint="Newly signed up businesses will be provided with this URL as your brand's support group."
        />
        <v-text-field
          v-model="brand.analytics_code"
          autocomplete="new-password"
          prepend-icon="mdi-google"
          label="Google Analytics Code"
          hint="Analytics code which looks like 'UA-102929549-7' or 'G-B31W08HFLV' - should be already attached to given Brand URL."
        />
        <v-text-field
          v-model="brand.facebook_url"
          autocomplete="new-password"
          label="Facebook URL"
          placeholder="{login/page-name}"
          prepend-icon="mdi-facebook"
          prefix="https://facebook.com/"
        />
        <v-text-field
          v-model="brand.twitter_url"
          autocomplete="new-password"
          label="Twitter"
          placeholder="{login/page-name}"
          prepend-icon="mdi-twitter"
          prefix="https://twitter.com/"
        />
        <v-text-field
          v-model="brand.instagram_url"
          autocomplete="new-password"
          label="Instagram"
          placeholder="{login/page-name}"
          prepend-icon="mdi-instagram"
          prefix="https://instagram.com/"
        />
      </div>
      <v-btn
        block
        rounded
        class="mr-0"
        color="primary"
        :disabled="!can_add_brand"
        @click="is_editing ? update_brand() : add_brand()"
      >
        <v-progress-circular
          v-if="processing_brand"
          size="25"
          color="primary"
          indeterminate
        />
        <span v-else>{{
          is_editing ? "Update Brand" : "Add New Brand"
        }}</span></v-btn
      >
    </v-form>
    <v-snackbar v-model="show_snackbar" top right color="yellow" timeout="2000"
      ><span class="black--text font-weight-bold"
        >Settings sucesfully saved</span
      ></v-snackbar
    >
  </div>
</template>

<script>
import ImagesCropper from "@/views/admin/components/ImagesCropper";
import {
  get_shops,
  add_brand,
  get_brand_by_id,
  update_brand,
} from "@/requests";
import { format_string_to_uid } from "@/utils/firestore";
import { field_req } from "@/utils/form_val_rules";
import { mapState, mapActions } from "vuex";

import { BrandZoneLabel } from "@/const";

const BRAND_TYPES = [
  { text: "Retail", value: "retail" },
  { text: "Hospitality", value: "hospitality" },
  { text: "I'am brand (single-shop)", value: "single_shop" },
];

const brand_header_placeholder = `This is a welcome text displayed on the banner of your home page with a big bold font. We would recommend something short and sweet.`;
const brand_subheader_placeholder = `This is a short descriptive text displayed underneath your header with a regular font. We would recommend a short sentence describing what your brand is.`;

export default {
  name: "AddNewBrand",
  components: {
    ImagesCropper,
  },
  data() {
    return {
      BRAND_TYPES,
      field_req,
      brand_header_placeholder,
      brand_subheader_placeholder,
      processing_brand: false,
      loading_shops: false,
      is_form_valid: false,
      search_shop: "",
      shops: [],
      brand: {
        url: "https://will-update-later.domain/",
        contact: "hello@wearelocals.co.uk",
        theme_color: "#EEEEEE",
      },
      brand_zone_labels: Object.values(BrandZoneLabel),
      brand_zone_preview: [],
      show_snackbar: false,
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    is_editing() {
      return Boolean(this.$route.meta.is_editing);
    },
    brand_id() {
      const brand = this.brand;
      if (brand.id) return brand.id;
      if (!brand.name) return;
      if (!brand.brand_type) return;
      if (brand.brand_type === "single_shop") {
        return `iam-${format_string_to_uid(brand.name)}`;
      }
      return format_string_to_uid(brand.name);
    },
    can_add_brand() {
      if (this.processing_brand) return false;
      const brand_type = this.brand.brand_type;
      if (!brand_type) return false;

      const is_form_valid = this.is_form_valid;
      const has_logo = this.brand.logo;
      const has_white_logo = this.brand.logo;
      const has_favicon = this.brand.favicon_url;
      const has_image = this.brand.image_url;

      if (brand_type === "single_shop") {
        return is_form_valid && has_logo && has_white_logo && has_favicon;
      } else {
        return (
          is_form_valid &&
          has_logo &&
          has_white_logo &&
          has_favicon &&
          has_image
        );
      }
    },
  },
  watch: {
    async search_shop(val) {
      val && (await this.search_shops(val));
    },
  },
  async mounted() {
    if (this.is_editing) {
      let { data: brand } = await get_brand_by_id(this.$route.params.brand_id);
      brand = this.apply_only_socials_page_names(brand);
      this.brand = { ...brand };
    }
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brands", "get_brand_by_id"]),
    apply_only_socials_page_names(brand) {
      if (brand.facebook_url) {
        brand.facebook_url = brand.facebook_url.replace(
          "https://facebook.com/",
          ""
        );
      }
      if (brand.twitter_url) {
        brand.twitter_url = brand.twitter_url.replace(
          "https://twitter.com/",
          ""
        );
      }
      if (brand.instagram_url) {
        brand.instagram_url = brand.instagram_url.replace(
          "https://instagram.com/",
          ""
        );
      }
      return brand;
    },
    process_social_urls(brand) {
      if (brand.facebook_url) {
        brand.facebook_url = `https://facebook.com/${brand.facebook_url}`;
      }
      if (brand.twitter_url) {
        brand.twitter_url = `https://twitter.com/${brand.twitter_url}`;
      }
      if (brand.instagram_url) {
        brand.instagram_url = `https://instagram.com/${brand.instagram_url}`;
      }
      return brand;
    },
    async search_shops(name) {
      this.loading_shops = true;
      try {
        const { data: shops } = await get_shops({ name });
        this.shops = shops;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_shops = false;
      }
    },
    async add_brand() {
      this.processing_brand = true;
      try {
        let brand_in = { id: this.brand_id, ...this.brand };
        brand_in = this.process_social_urls(brand_in);

        await add_brand(brand_in);
        await this.get_brands();
        this.$router.replace({ name: "admin-brands" });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_shops = false;
        this.processing_brand = false;
      }
    },
    async update_brand() {
      this.processing_brand = true;
      try {
        let brand_in = { ...this.brand };
        brand_in = this.process_social_urls(brand_in);
        await update_brand(this.brand.id, brand_in);
        await this.get_brand_by_id(this.selected_brand.id);
        this.show_snackbar = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_shops = false;
        this.processing_brand = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-self-start {
  justify-self: flex-start;
}
.bordered {
  background-color: white;
  border-radius: 10px;
}
.light-grey-text {
  color: #00000099;
}
.brand-form {
  width: 50vw;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: unset;
  }

  .disabled {
    cursor: not-allowed;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(238, 238, 238, 0.3);
      z-index: 10;
    }
  }

  .img-preview-box {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    .image-preview {
      margin: auto;
      height: 56px;
      width: 200px;
      background-position: center center;
      background-repeat: initial;
      background-size: contain, auto;

      &.--home-img {
        width: 192px;
        height: 108px;
      }

      &.--favicon {
        background-repeat: no-repeat;
        background-size: contain, auto;
      }
    }
  }
}
.google-preview {
  border: 1px solid var(--v-primary-base);
  .results-returned {
    element.style {
      color: #777;
      padding-top: 10px;
      padding-bottom: 15px;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0px;
    .result-link {
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 2px;
      a {
        color: #1a0dab !important;
      }
    }
    .green-link {
      color: #006621;
      max-width: 650px;
      display: inline-block;
      margin-bottom: 5px;
      margin-top: 0px;
    }
    p {
      margin-bottom: 10px;
      margin-top: 0px;
    }
    li a {
      text-decoration-line: none;
      margin-bottom: 7px;
      margin-top: 0px;
    }
  }
}
</style>
