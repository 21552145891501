import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=0b1821ab&scoped=true&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"
import style0 from "./edit.vue?vue&type=style&index=0&id=0b1821ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1821ab",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VAutocomplete,VBtn,VChip,VColorPicker,VForm,VProgressCircular,VSelect,VSnackbar,VTextField,VTextarea})
